<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <CreateIssueDialog
      :dialogCreateIssue="dialogCreateIssue"
      :selectedFilesFromDrop="selectedFilesFromDrop"
      @clicked="dialogCreateIssueEmit"
    />
    <v-app-bar app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon
        color="background"
        v-if="$vuetify.breakpoint.name == 'xs'"
        @click="navDrawer = !navDrawer"
      ></v-app-bar-nav-icon>
      <v-card class="elevation-0">
        <v-img contain width="70px" src="@/assets/ChainHome.png"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <span>
        <div class="PrimaryFontColorSmall">
          {{ $store.getters.get_user_email }}
        </div>
      </span>
      <v-tooltip bottom>
        <!--dialogCreateIssue = true  -->
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="htmlToImageMethod()"
            color="primary"
            :disabled="overlay == true ? true : false"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using ChainHome Bms ? Click To Report</span>
      </v-tooltip>
      <v-btn icon to="/">
        <v-icon color="primary" large dark>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="navDrawer" app clipped width="190px" color="primary" dark>
      <v-list dark dense rounded>
        <v-list-item-group link dense rounded v-model="selectedItem" color="white" active-class="white" no-action>
          <v-list-item
            color="primary"
            v-for="(item, idx) in RouterList"
            :key="idx"
            @click="$route.name != item.MenuRoute && item.has_child_routes == false ? $router.push(item.MenuRoute) : ''"
          >
            <v-list-item-icon class="pr-0 mr-0">
              <v-icon small color="white" v-text="item.MenuICon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="px-0 mx-0" :to="item.MenuRoute" link>
              <v-list-item-title :class="selectedItem == idx ? 'black--text' : 'white--text'">{{
                item.MenuName
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div ref="home">
        <v-card
          class="elevation-0"
          @drop.prevent="onDrop($event)"
          @dragover.prevent="onDragOver($event)"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="onDragLeave($event)"
        >
          <router-view v-if="dragover == false" />
          <v-card class="elevation-0" v-else :height="Height">
            <v-card fill-height fluid :height="`${Height - 120}`" class="elevation-0 borderDotted ma-8">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-icon size="60px" color="primary">mdi-paperclip-plus</v-icon>
                    <h2 class="mt-2">Please Drag And Drop An Attachment To Report And Issue</h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import RouterList from "@/JsonFiles/RouterList.json";
import Overlay from "@/components/Extras/Overlay.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import CreateIssueDialog from "@/components/Extras/CreateIssueDialog.vue";
import * as htmlToImage from "html-to-image";
export default {
  mixins: [GetCurrentUser],
  components: {
    Overlay,
    CreateIssueDialog,
  },
  data: () => ({
    IssueReportDialog: false,
    dialogCreateIssue: false,
    dragover: false,
    Height: 0,
    selectedFilesFromDrop: [],
    navDrawer: true,
    overlay: false,
    RouterList: [],
    selectedItem: 0,
  }),
  watch: {
    "$route.name"(value) {
      console.log("check the value", value);
      this.overlay = false;
    },
  },
  mounted() {
    this.RouterList = RouterList;
    this.GetCurrentUser();
    this.updateNavigationValueMethod();
  },
  methods: {
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.selectedFilesFromDrop.push(blob);
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
          self.$router.push("ReportIssue");
        });
        // self.overlay = false;
      }, 2000);
    },
    updateNavigationValueMethod() {
      console.log("route name", this.$route.name);
      let previous_route = this.$store.getters.get_previous_route;
      if (previous_route && previous_route != "") {
        switch (previous_route) {
          case "Organizations":
            this.selectedItem = 0;
            break;
          case "Assets":
            this.selectedItem = 1;
            break;
          case "PlantTypes":
            this.selectedItem = 2;
            break;
          case "MasterTags":
            this.selectedItem = 3;
            break;
          case "Users":
            this.selectedItem = 4;
            break;
          case "AuditLogs":
            this.selectedItem = 5;
            break;
        }
      }

      this.$forceUpdate();
    },
    onDragLeave() {
      this.dragover = false;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDragOver() {
      this.dragover = true;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDrop(event) {
      console.log("Check The Event", event.dataTransfer.files);
      this.selectedFilesFromDrop = event.dataTransfer.files;
      this.dialogCreateIssue = true;
      this.$forceUpdate();
    },
    dialogCreateIssueEmit(Toggle) {
      this.loading = false;
      this.selectedFiles = [];
      this.dialogCreateIssue = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
