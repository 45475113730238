<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>
<style>
.primaryColor {
  color: rgb(3, 102, 144) !important;
}
.maxWidthStatus {
  max-width: 150px !important;
}
.PrimaryFontColor {
  font-size: 23px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.PrimaryFontMediumNormalColor {
  font-size: 15px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.PrimaryFontMediumBoldColor {
  font-size: 20px !important;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.FontSize {
  font-size: 12px !important;
}
.smallFontSize {
  font-size: 6px !important;
}
.GreenHeader {
  color: green;
}
.RedHeader {
  color: red;
}
.BlackHeader {
  color: black;
  font-weight: bold;
}
.BlueHeader {
  color: blue;
}
.maxWidthLarge {
  max-width: 250px !important;
}
.maxWidth {
  max-width: 170px !important;
}
.v-input .v-label {
  font-size: 12px;
}
.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}
.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}
.fontSize {
  font-size: 12px !important;
}
.fontSizeSmall {
  font-size: 10px !important;
}
.borderClass {
  border: 2px solid #5d2e82 !important;
}
.borderRadiusZeroColor {
  border-radius: 0px !important;
  border: 1px solid #5d2e82 !important;
}
.pointer {
  cursor: pointer;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
