import { getCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCurrentUser = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUser() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(getCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.$store.commit("SET_CURRENTUSER_DETAILS", JSON.parse(result.data.GetCurrentUserDetails).data.items[0]);
        if (this.$route.name == "LandingPage") {
          this.$router.push("/Organizations");
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.log(error);
        this.$router.push("/");
      }
    },
  },
};
