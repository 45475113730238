/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserDetailsInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    ListUsers
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations {
    ListOrganizations
  }
`;
export const listPlantTypes = /* GraphQL */ `
  query ListPlantTypes($input: ListPlantTypesInput) {
    ListPlantTypes(input: $input)
  }
`;
export const listMasterParameters = /* GraphQL */ `
  query ListMasterParameters {
    ListMasterParameters
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions($input: ListDivisionsInput) {
    ListDivisions(input: $input)
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices($input: ListDevicesInput) {
    ListDevices(input: $input)
  }
`;
export const listAllAssetsAcrossOrganization = /* GraphQL */ `
  query ListAllAssetsAcrossOrganization(
    $input: ListAllAssetsAcrossOrganizationInput
  ) {
    ListAllAssetsAcrossOrganization(input: $input)
  }
`;
export const listTagsOfOrganization = /* GraphQL */ `
  query ListTagsOfOrganization($input: ListTagsOfOrganizationInput) {
    ListTagsOfOrganization(input: $input)
  }
`;
export const listPlantTypesOfOrganization = /* GraphQL */ `
  query ListPlantTypesOfOrganization(
    $input: ListPlantTypesOfOrganizationInput
  ) {
    ListPlantTypesOfOrganization(input: $input)
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs($input: ListLogsInput) {
    ListLogs(input: $input)
  }
`;
