import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Amplify from "aws-amplify";
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_wxShTUL9E",
    region: "ap-south-1",
    userPoolWebClientId: "387i8ioq4r2v97jc08fmj32tqj",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://vcojjj3v6vbe7hviz7fnfjyfxu.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
      {
        name: "CHAINPORTAL",
        endpoint: "https://chc5fvje6c.execute-api.ap-south-1.amazonaws.com/chainhome/",
      },
    ],
  },
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
